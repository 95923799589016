import styled from 'styled-components';
import { SOCIAL_LIST } from 'src/constants/layout';
import WidgetCookie from 'src/components/widgets/widget-cookie';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { KEY_ADS_POSITION } from '../ads/dynamic-ads';
import MediaQuery from 'react-responsive';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { APP_IMG, IS_SHOW_COOKIE } from 'src/constants';
import { isExistAds } from 'src/utils/helper';
import dynamic from 'next/dynamic';
const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'));

const Footer = ({ data, ads }) => {
  // console.log('Footer', data);

  const router = useRouter();
  return (
    <>
      {/* --------------- WidgetCookie --------------- */}
      <StickyCookie className='sticky-cookie col-12'>
        {router.pathname === '/' && isExistAds(ads, KEY_ADS_POSITION.POSTARTICLE) && <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />}
        {/* // ----------------------------- ADS : POSTARTICE -------------------------- */}
        <div>
          {router.pathname === '/[cid]/[id]' && isExistAds(ads, KEY_ADS_POSITION.POSTARTICLE) && (
            <MediaQuery maxWidth={480}>
              <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />
            </MediaQuery>
          )}
        </div>
        {/* {IS_SHOW_COOKIE === 'true' && <WidgetCookie />} */}
      </StickyCookie>
      {/* AD1X1 IN CATEGORY */}
      {(router.pathname === '/[cid]' || router.pathname === '/[cid]/[id]' || router.pathname === '/') && isExistAds(ads, KEY_ADS_POSITION.AD1X1) && (
        <div className='ads --mb-0'>
          <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />
        </div>
      )}
      <FooterComponents className='footer'>
        <section className='container footer-wrapper'>
          <div className='row footer-separator'>
            <div className='col-md-2 col-12 footer footer-left'>
              <img className='logo-img' alt='logo-footer' title='logo-footer' src={`${APP_IMG}/images/cropped-khobsanam-logo-1.png`} loading='lazy' width='48' height='48' />
              {/* </Link> */}
            </div>
            <div className='col-md-5 col-12 footer footer-center'>
              <ul>
                {!_.isEmpty(data) &&
                  data.map((item, index) => (
                    <li key={index}>
                      <a
                        aria-label={item?.nameTh}
                        title={item?.nameTh}
                        href={`/${item.link}`}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK_POSITION,
                            router: router?.pathname,
                            section: 'footer',
                            position: 'on:footer',
                            data: {
                              title: item?.nameTh,
                              heading: 'footer'
                            }
                          })
                        }
                      >
                        {item?.nameTh}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className='col-md-5 col-md-12 footer footer-right'>
              {SOCIAL_LIST.map((item, index) => (
                <a
                  key={index}
                  aria-label='social'
                  title='social'
                  href={item.link}
                  target={'_blank'}
                  rel={'nofollow'}
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_POSITION,
                      router: router?.pathname,
                      position: 'on:footer',
                      section: 'footer',
                      data: {
                        title: item?.name,
                        heading: item?.name,
                        index: index + 1
                      }
                    })
                  }
                >
                  <div className='bg-item'>{item.icon}</div>
                </a>
              ))}
            </div>
          </div>
          <div className='footer-item footer footer-sonp'>
            <img src={`${APP_IMG}/images/logo_sonp.png`} alt='logo_sonp' title='logo_sonp' width='180' height='60' />
          </div>
          <div className='footer-item footer footer-copyright'>
            <span>
              {' '}
              {`Ⓒ ${new Date().getFullYear()} -`} <p> {'Khobsanam'}</p> {'All Rights Reserved.'}{' '}
            </span>
            <div id='truehits_div'></div>
          </div>
        </section>
      </FooterComponents>
    </>
  );
};

const StickyCookie = styled.div`
  position: sticky;
  bottom: 0;
  display: block;
  z-index: 9;

  @media (min-width: 769px) {
    margin-top: 30px;
    margin-bottom: -30px;
  }
  @media (max-width: 768px) {
    position: fixed !important;
  }
  @media (max-width: 480px) {
    position: sticky !important;
    display: flex !important;
    flex-direction: column;
  }
`;

const FooterComponents = styled.footer`
  background-color: #010b2b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;

  .footer-wrapper {
    .footer-separator {
      justify-content: space-between;
      color: white;
      border-top: 2px solid #00ff57;
      padding-top: 20px;
      .footer {
        @media (max-width: 767px) {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
        }
      }
    }
    .footer-left {
      img {
        max-width: 123px;
        width: 100%;
        height: auto;
      }
    }
    .footer-center {
      ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
        width: 100%;
        li {
          list-style-type: none;
          float: left;
          width: 50%;
          margin-bottom: 20px;
          @media (max-width: 768px) {
            text-align: center;
          }
          a {
            color: white;
            text-transform: uppercase;
            transition: all 0.5s ease-in-out;
            &:hover {
              color: #00ff57;
            }
          }
        }
      }
    }
    .footer-right {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      a:last-child {
        margin-left: 15px;
        svg {
          font-size: 28px;
        }
      }
      .bg-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background-color: #00ff57;
        border: 1px solid #00ff57;
        border-radius: 3px;
        svg {
          color: rgba(0, 2, 28, 0.95);
        }
        &:hover {
          background-color: rgba(0, 2, 28, 0.95);
          svg {
            color: #00ff57;
          }
        }
      }
    }

    .footer-item {
      margin-bottom: 24px;

      &.footer-sonp {
        max-width: 100%;
        width: 180px;
        filter: drop-shadow(0 1px 1px rgba(0, 2, 28, 0.95));
        margin-left: auto;
        margin-right: auto;
      }
      &.footer-copyright {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        p {
          display: inline-block;
          color: #00ff57;
        }
      }
    }
  }
`;
export default Footer;
